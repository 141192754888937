class ZIDXInquiry implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxInquiryContainer";
    }
    getMatchURL(){
        return "/inquiry/";
    }
    render(){
        // console.log("inquiry");
        window.zidxBase.setFieldFromLocalStorage(".zidxInquiryContainer", "firstName", "local_lead_first_name");
        window.zidxBase.setFieldFromLocalStorage(".zidxInquiryContainer", "lastName", "local_lead_last_name");
        window.zidxBase.setFieldFromLocalStorage(".zidxInquiryContainer", "phone", "local_lead_phone");
        window.zidxBase.setFieldFromLocalStorage(".zidxInquiryContainer", "email", "local_lead_email");
    }
}